import axios from 'axios'
import store from '../../../store/store'

const url = store.state.config.konecta

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}
const token = _getToken()
const headers = {
  authorization: `Bearer ${token}`
}

export default {
  promoteSurveyVersion(versionId) {
    const data = {
      active: true
    }
    return axios({
      method: 'put',
      url: url + `/version/${versionId}`,
      data,
      headers
    })
  },
  deleteSurveyVersion(versionId) {
    return axios({
      method: 'delete',
      url: url + `/version/` + versionId,
      headers
    })
  },
  async hasActiveVersion(versionId) {
    if (versionId) {
      return axios({
        method: 'get',
        url: `${url}/survey/${versionId}/has-active-version`,
        headers
      })
    } else {
      return false
    }
  },
  postSurveyVersion(serviceId, tag, production, fork, cantSurvey) {
    const data = {
      service: serviceId,
      active: production,
      cantSurvey: parseInt(cantSurvey)
    }
    if (tag && tag.trim().length > 0) {
      data.tag = tag
    }
    if (fork) {
      data.fork = fork
    }
    return axios({
      method: 'post',
      url: url + `/version`,
      data,
      headers
    })
  },
  editSurveyVersion(versionId, newVersion) {
    const data = {
      active: !!newVersion.production
    }
    const tag = newVersion.tag
    if (tag && tag.trim().length > 0) {
      data.tag = tag
    }
    data.cantSurvey = parseInt(`${newVersion.cantSurvey}` || '0')
    return axios({
      method: 'put',
      url: url + `/version/${versionId}`,
      data,
      headers
    })
  },
  deleteSurvey(surveyId, deleteMessages) {
    if (!deleteMessages) {
      return axios({
        method: 'delete',
        url: url + `/survey/` + surveyId,
        headers
      })
    } else {
      return axios({
        method: 'delete',
        url: url + `/survey/withMessages/` + surveyId,
        headers
      })
    }
  },
  postSurvey(name) {
    const data = {
      service: {
        name: name,
        users: []
      }
    }
    return axios({
      method: 'post',
      url: url + `/survey`,
      data,
      headers
    })
  },
  putSurveyDialogs(survey, version, payload) {
    function clean(obj) {
      for (let propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
          delete obj[propName]
        }
      }
    }
    const data = JSON.parse(JSON.stringify(payload))
    data.forEach(element => {
      clean(element)
    })
    const aux = []
    let acumuladorDeMensajes = []
    data.forEach(element => {
      if (element.isMessage) {
        acumuladorDeMensajes.push(element.question)
      } else {
        aux.push(element)
        aux[aux.length - 1].question = acumuladorDeMensajes.concat(
          aux[aux.length - 1].question
        )
        acumuladorDeMensajes = []
        delete aux[aux.length - 1].isMessage
      }
    })
    // console.log('AUX AUX AUX', JSON.stringify(aux, undefined, 2))
    return axios({
      method: 'put',
      url: url + `/survey/${survey}/dialogs?version=${version}`,
      data: aux,
      headers
    })
  },
  getSurveyTree(version) {
    return axios({
      method: 'get',
      url: url + `/version/${version}/tree`,
      headers
    })
  },
  getVersions(service) {
    return axios({
      method: 'get',
      url: url + `/version?where[service]=${service}`,
      headers
    })
  },
  getSurveys() {
    return axios({
      method: 'get',
      url: url + `/survey`,
      headers
    })
  },
  async getChatData(service, version, senderId) {
    try {
      const chat = await axios({
        method: 'get',
        url:
          url +
          '/chat' +
          `?where[service]=${service}&where[version]=${version}&where[senderId]=${senderId}`,
        headers
      })
      return chat.data
    } catch (ex) {
      console.error(ex)
    }
  },
  async sendSurvey(sender, botId, surveyServiceId) {
    const data = {
      entry: [
        {
          messaging: [
            {
              message: {
                text: '...'
              }
            }
          ]
        }
      ],
      sender: sender,
      events: [
        {
          key: 'start survey',
          options: {
            service: surveyServiceId
          }
        }
      ]
    }
    try {
      await axios({
        method: 'post',
        url: url + '/conversation/web/' + botId,
        data,
        headers
      })
    } catch (ex) {
      console.error(ex)
      return null
    }
  },
  async createSurveyDialogs(surveyId) {
    const d1 = {
      name: 'pide nombre completo',
      previousName: 'saluda y pide dni',
      condition: {
        params: {
          regex: '^\\d+$'
        },
        tree: {
          funcs: ['match'],
          facts: [null],
          operator: '...'
        }
      },
      previousQuestions: [
        {
          platforms: ['all'],
          responseText: 'Bienvenido, por favor ingresa tu DNI'
        }
      ],
      reQuestions: [
        {
          platforms: ['all'],
          responseText: 'Lo siento, debes ingresar tu DNI'
        }
      ],
      questions: [
        {
          platforms: ['all'],
          responseText: 'Ingresa tu nombre completo'
        }
      ],
      actions: [
        {
          key: 'save survey response',
          params: ['dni']
        }
      ]
    }
    const d2 = {
      name: 'fin',
      condition: {
        params: {
          regex: '^[^\\d]+$'
        },
        tree: {
          funcs: ['match'],
          facts: [null],
          operator: '...'
        }
      },
      reQuestions: [
        {
          platforms: ['all'],
          responseText: 'Lo siento, debes ingresar tu nombre completo'
        }
      ],
      questions: [
        {
          platforms: ['all'],
          responseText: 'Eso es todo, muchas gracias!'
        }
      ],
      actions: [
        {
          key: 'save survey response',
          params: ['fullname']
        },
        {
          key: 'finish survey',
          params: []
        }
      ]
    }
    try {
      await axios({
        method: 'post',
        url: url + '/dialog/survey/' + surveyId,
        data: d1,
        headers
      })
      await axios({
        method: 'post',
        url: url + '/dialog/survey/' + surveyId,
        data: d2,
        headers
      })
    } catch (ex) {
      console.error(ex)
      return null
    }
  },
  async createSurvey(name) {
    const surveyBody = {
      service: {
        name: name,
        users: []
      }
    }
    try {
      const survey = await axios({
        method: 'post',
        url: url + '/survey',
        data: surveyBody,
        headers
      })
      // console.log('survey data', survey.data)
      const service = survey.data.service
      const version = await axios({
        method: 'post',
        url: url + '/version',
        data: {
          service,
          active: true
        },
        headers
      })
      return {
        survey: survey.data,
        surveyVersion: version.data
      }
    } catch (ex) {
      console.error(ex)
      return null
    }
  },
  async createDialogs(versionId) {
    const dialogSaludo = {
      version: versionId,
      name: 'saludo',
      type: 'node',
      condition: {
        params: {
          intent: 'saludo'
        },
        tree: {
          funcs: ['intent'],
          facts: [null],
          operator: '...'
        }
      },
      responses: [
        {
          platforms: ['all'],
          responseText: 'Hola que tal! Cómo estás!?'
        }
      ]
    }
    const dialogDespedida = {
      version: versionId,
      name: 'despedida',
      type: 'node',
      condition: {
        params: {
          intent: 'despedida'
        },
        tree: {
          funcs: ['intent'],
          facts: [null],
          operator: '...'
        }
      },
      responses: [
        {
          platforms: ['all'],
          responseText: 'Hasta luego 👋'
        }
      ]
    }
    const dialogAnythingElse = {
      version: versionId,
      name: 'anything_else',
      type: 'node',
      condition: {
        params: {},
        tree: {
          funcs: ['true'],
          facts: [null],
          operator: '...'
        }
      },
      responses: [
        {
          platforms: ['all'],
          responseText: 'Lo siento, no entendí 😳'
        }
      ]
    }
    try {
      await axios({
        method: 'post',
        url: url + '/dialog',
        data: dialogSaludo,
        headers
      })
      await axios({
        method: 'post',
        url: url + '/dialog',
        data: dialogDespedida,
        headers
      })
      await axios({
        method: 'post',
        url: url + '/dialog',
        data: dialogAnythingElse,
        headers
      })
      return
    } catch (ex) {
      console.error(ex)
      return ex
    }
  },
  async createBotIntents(versionId) {
    const intents = [
      {
        name: 'saludo',
        examples: [
          'hola',
          'holis',
          'buenas',
          'que tal',
          'buen día',
          'holaaa',
          'ola',
          'buenas noches',
          'buenas tardes'
        ]
      },
      {
        name: 'despedida',
        examples: [
          'chau',
          'bye',
          'nos vemos',
          'hasta luego',
          'ta chau',
          'adiós',
          'listo chau',
          'chau gracias',
          'hasta nunca'
        ]
      }
    ]
    try {
      for (let i = 0; i < intents.length; i += 1) {
        await axios({
          method: 'post',
          url: url + '/intent',
          data: {
            name: intents[i].name,
            examples: intents[i].examples,
            version: versionId
          },
          headers
        })
      }
      return
    } catch (ex) {
      console.error(ex)
      return ex
    }
  },
  async createBot(name, surveys) {
    const botBody = {
      service: {
        name: name,
        users: []
      },
      nlu: {
        platform: 'rasa',
        culture: 'es-ES'
      },
      noIntentMessage1: 'no entendí',
      noIntentAction: {
        name: 'Texto',
        key: 'responseText',
        args: ['no entendí']
      },
      connectionErrorMessage: 'error de conexión',
      availability: {
        exceptions: [],
        days: [
          {
            day: 0,
            start: 0,
            end: 0
          },
          {
            day: 1,
            start: 0,
            end: 0
          },
          {
            day: 2,
            start: 0,
            end: 0
          },
          {
            day: 3,
            start: 0,
            end: 0
          },
          {
            day: 4,
            start: 0,
            end: 0
          },
          {
            day: 5,
            start: 0,
            end: 0
          },
          {
            day: 6,
            start: 0,
            end: 0
          }
        ]
      }
    }
    try {
      const bot = await axios({
        method: 'post',
        url: url + '/bot',
        data: botBody,
        headers
      })
      // console.log('bot data', bot.data)
      const service = bot.data.service
      const version = await axios({
        method: 'post',
        url: url + '/version',
        data: {
          service,
          active: true,
          surveys
        },
        headers
      })
      return {
        bot: bot.data,
        botVersion: version.data
      }
    } catch (ex) {
      console.error(ex)
      return null
    }
  }
}
